import type { CountryCodes, Languages } from 'shared/util/calendar';
import { generateDateItems, type CalendarEvent, type DateItem } from './utils/dates';
import { CalendarDay, type CalendarDayProps } from './components/calendarDay/CalendarDay';
import { WeekdayHeader } from './components/weekdayHeader/WeekdayHeader';

export type CalendarProps = {
    className?: string;
    countryCode: CountryCodes;
    createDayProps?: (dateItem: DateItem) => Partial<CalendarDayProps>;
    events: CalendarEvent[];
    language: Languages;
    offsetStartDay: number;
    onDaySelect: (dateItem: DateItem) => void;
    activeMonthDate: Date;
    today: Date;
    dateText?: { [key: string]: string };
    dayDataByMonth: Record<string, Record<string, string>>;
};

export const Calendar = ({
    className,
    countryCode,
    dayDataByMonth,
    events = [],
    language,
    offsetStartDay,
    onDaySelect,
    activeMonthDate,
    createDayProps,
    today,
}: CalendarProps) => {
    today.setHours(0, 0, 0, 0);
    const year = activeMonthDate.getFullYear();
    const monthIndex = activeMonthDate.getMonth();
    const dateItems = generateDateItems({ dayDataByMonth, events, monthIndex, offsetStartDay, today, year });

    return (
        <div className={className ?? ''}>
            <div className="Calendar-columnTitleList">
                <WeekdayHeader language={language} offsetStartDay={offsetStartDay} />
            </div>

            <div className="Calendar-calendar" data-testid="Calendar-dayList">
                {dateItems.map((dateItem) => {
                    const dayProps = typeof createDayProps === 'function' ? createDayProps(dateItem) : {};
                    const eventClassNames = dateItem.activeEvents?.reduce((acc, event) => {
                        const displayEventType = `${event.type.substring(0, 1).toUpperCase()}${event.type.substring(1)}`;

                        switch (event.status) {
                            case 'start':
                                return `${acc} CalendarDay--event${displayEventType} CalendarDay--event${displayEventType}Start`;
                            case 'between':
                                return `${acc} CalendarDay--event${displayEventType} CalendarDay--event${displayEventType}Between`;
                            case 'end':
                                return `${acc} CalendarDay--event${displayEventType} CalendarDay--event${displayEventType}End`;
                            case 'start-end':
                                return `${acc} CalendarDay--event${displayEventType} CalendarDay--event${displayEventType}StartEnd`;
                            default:
                                break;
                        }
                    }, '');
                    const date = dateItem.date;

                    return (
                        <CalendarDay
                            className={eventClassNames || ''}
                            countryCode={countryCode}
                            dateItem={dateItem}
                            key={date.getTime()}
                            onDaySelect={onDaySelect}
                            displayedMonthNumber={monthIndex}
                            {...dayProps}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export * from 'shared/util/calendar';
export * from './utils/dates';
export type { CalendarDayProps };
