import { IconChevronRight16, IconChevronLeft16 } from '@fabric-ds/icons/react';
import cn from 'classnames';

import { Direction } from '../../util/calendar';

interface NavBtnProps {
    direction: Direction;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
    testId?: string;
}

export const NavBtn = ({ direction, onClick, disabled, className, testId }: NavBtnProps) => {
    const isLeft = direction === Direction.Left;

    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={cn('date-picker__nav-btn', className)}
            aria-label={`${isLeft ? 'forrige' : 'neste'} måned`}
            title={`${isLeft ? 'Forrige' : 'Neste'} måned`}
            data-testid={testId}>
            {isLeft ? (
                <IconChevronLeft16 aria-hidden="true" focusable="false" />
            ) : (
                <IconChevronRight16 aria-hidden="true" focusable="false" />
            )}
        </button>
    );
};
