import type { CalendarEvent } from '@/components/calendar/Calendar';
import { type AvailabilityPerDate, EventTypes } from '@/domain';

type WeekdayName = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getDayIndexFromWeekdayName = (weekdayName: WeekdayName): number => {
    const dayIndex = days.indexOf(weekdayName.toLowerCase());

    return dayIndex;
};

export const isDateEqual = (date1: Date, date2: Date): boolean =>
    date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();

export const moveBookingEventEndDateBackOneDay = (bookingEvent: CalendarEvent): CalendarEvent => {
    const dayBeforeEndDate = new Date(bookingEvent.endDate.getTime());
    dayBeforeEndDate.setDate(bookingEvent.endDate.getDate() - 1);

    return {
        ...bookingEvent,
        endDate: dayBeforeEndDate,
    };
};

export const moveBookingEventStartDateForwardOneDay = (bookingEvent: CalendarEvent): CalendarEvent => {
    const dayAfterStartDate = new Date(bookingEvent.startDate.getTime());
    dayAfterStartDate.setDate(bookingEvent.startDate.getDate() + 1);

    return {
        ...bookingEvent,
        startDate: dayAfterStartDate,
        endDate: bookingEvent.endDate.getTime() === bookingEvent.startDate.getTime() ? dayAfterStartDate : bookingEvent.endDate,
    };
};

// Get the event directly following the selected event
export const getBookingEventFollowingSelectionEvent = ({
    bookingEvents,
    selectEvent,
}: {
    bookingEvents: CalendarEvent[];
    selectEvent: CalendarEvent;
}) => bookingEvents.find((item) => item.startDate.getTime() > selectEvent.startDate.getTime());

export const getInvalidCheckinDayEventListPerDate = ({
    bookingEvents,
    availabilityPerDate,
    activeMonthDate,
    today,
}: {
    bookingEvents: CalendarEvent[];
    availabilityPerDate?: AvailabilityPerDate[];
    activeMonthDate: Date;
    today: Date;
}): CalendarEvent[] => {
    const invalidCheckinDateList = availabilityPerDate
        ?.filter((rule) => {
            const ruleDate = new Date(rule.date);
            return ruleDate.getFullYear() === activeMonthDate.getFullYear() && ruleDate.getMonth() === activeMonthDate.getMonth();
        })
        ?.filter((rule) => !rule.checkin || !rule.available)
        .map((rule) => new Date(rule.date).getDate());

    const invalidCheckinDayEventList: CalendarEvent[] = [];

    // Add invalidCheckinDay events for the entire selectedMonth
    //
    // `totalDaysInGridMonth`: Add 6 days to always include next month overflow days in the calendar grid
    const totalDaysInGridMonth = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 1, 0).getDate() + 6;
    const firstDayOfSelectedMonth = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 1, 1);
    const dayToStart =
        (today.getMonth() === activeMonthDate.getMonth() && today.getFullYear() === activeMonthDate.getFullYear()
            ? today.getDate()
            : firstDayOfSelectedMonth.getDate()) - 1; // `- 1` because it should include the dayToStart

    // Filter for bookingEvents including this month and next month to not apply invalidCheckinDay event to
    const bookingEventsDatesNotToApplyInvalidCheckinDays = bookingEvents.filter((item) => {
        const monthAfterNextDate = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 2, 1);
        return item.startDate.getTime() >= activeMonthDate.getTime() && item.startDate.getTime() < monthAfterNextDate.getTime();
    });

    for (let i = dayToStart; i < totalDaysInGridMonth; i++) {
        const date = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth(), i + 1);
        const isDayBookingEvent = bookingEventsDatesNotToApplyInvalidCheckinDays.some(
            (item) => date.getTime() >= item.startDate.getTime() && date.getTime() < item.endDate.getTime(),
        );

        if (isDayBookingEvent) {
            continue;
        }

        if (invalidCheckinDateList?.includes(date.getDate()) || availabilityPerDate?.length === 0) {
            invalidCheckinDayEventList.push({
                id: `day-${i}`,
                type: EventTypes.INVALID_CHECKIN_DAY,
                startDate: date,
                endDate: date,
            });
        }
    }

    return invalidCheckinDayEventList;
};

export const isAfterSelectedDate = (selected: Date, other: Date) => {
    return new Date(other).setHours(0, 0, 0, 0) > new Date(selected).setHours(0, 0, 0, 0);
};

export const isOnSameDay = (a: Date, b: Date) => {
    const aStartOfDay = new Date(a).setHours(0, 0, 0, 0);
    const bStartOfDay = new Date(b).setHours(0, 0, 0, 0);
    return aStartOfDay === bStartOfDay;
};

export const collidesWithMinRentalDurationEvent = (minDurationEvents: CalendarEvent[], date: Date) => {
    const atStartOfDay = new Date(date).setHours(0, 0, 0, 0);
    return minDurationEvents.some((event) => {
        const eventAtStartOfDay = new Date(event.endDate).setHours(0, 0, 0, 0);
        return atStartOfDay <= eventAtStartOfDay;
    });
};

export const getInvalidCheckoutDayEventListPerDate = ({
    bookingEvents,
    availabilityPerDate,
    activeMonthDate,
    selectedStartDate,
    minRentalDurationEvents,
}: {
    bookingEvents: CalendarEvent[];
    availabilityPerDate?: AvailabilityPerDate[];
    activeMonthDate: Date;
    selectedStartDate: Date;
    minRentalDurationEvents: CalendarEvent[];
}): CalendarEvent[] => {
    const invalidCheckoutDateList = availabilityPerDate
        ?.filter((rule) => {
            const ruleDate = new Date(rule.date);
            return (
                ruleDate.getFullYear() === activeMonthDate.getFullYear() &&
                ruleDate.getMonth() === activeMonthDate.getMonth() &&
                isAfterSelectedDate(selectedStartDate, ruleDate) &&
                !collidesWithMinRentalDurationEvent(minRentalDurationEvents, ruleDate)
            );
        })
        ?.filter((rule) => !rule.checkout)
        .map((rule) => new Date(rule.date).getDate());

    const invalidCheckoutDayEventList: CalendarEvent[] = [];
    // Add invalidCheckoutDay events for the entire selectedMonth
    //
    // `totalDaysInGridMonth`: Add 6 days to always include next month overflow days in the calendar grid
    const totalDaysInGridMonth = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 1, 0).getDate() + 6;
    const firstDayOfSelectedMonth = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 1, 1);

    const dayToStart =
        selectedStartDate.getMonth() === activeMonthDate.getMonth() && selectedStartDate.getFullYear() === activeMonthDate.getFullYear()
            ? selectedStartDate.getDate()
            : firstDayOfSelectedMonth.getDate() - 1; // `- 1` because it should include the dayToStart

    // Filter for bookingEvents including this month and next month to not apply invalidCheckoutDay event to
    const bookingEventsDatesNotToApplyInvalidCheckoutDays = bookingEvents.filter((item) => {
        const monthAfterNextDate = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 2, 1);
        return item.startDate.getTime() >= activeMonthDate.getTime() && item.startDate.getTime() < monthAfterNextDate.getTime();
    });
    for (let i = dayToStart; i < totalDaysInGridMonth; i++) {
        const date = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth(), i + 1);
        const isDayBookingEvent = bookingEventsDatesNotToApplyInvalidCheckoutDays.some(
            (item) => date.getTime() >= item.startDate.getTime() && date.getTime() <= item.endDate.getTime(),
        );

        if (isDayBookingEvent) {
            continue;
        }

        if (invalidCheckoutDateList?.includes(date.getDate()) || availabilityPerDate?.length === 0) {
            invalidCheckoutDayEventList.push({
                id: `day-${i}`,
                type: EventTypes.INVALID_CHECKOUT_DAY,
                startDate: date,
                endDate: date,
            });
        }
    }

    return invalidCheckoutDayEventList;
};

export const getExpiredEventsWithSelectEvent = ({
    selectedStartDate,
    activeMonthDate,
}: {
    selectedStartDate: Date;
    activeMonthDate: Date;
}): CalendarEvent[] => {
    const expiredEvents: CalendarEvent[] = [];

    // No expired events to add if it's the first of the month
    if (selectedStartDate.getDate() === 1) {
        return expiredEvents;
    }

    // Don't add expired events if the selectedStartDate is in the past
    if (activeMonthDate.getTime() > selectedStartDate.getTime()) {
        return expiredEvents;
    }

    const selectedStartMonthDate = new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), 1);
    const totalDaysInActiveMonth = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + 1, 0).getDate();
    const totalDays =
        activeMonthDate.getTime() < selectedStartMonthDate.getTime() ? totalDaysInActiveMonth + 1 : selectedStartDate.getDate();

    for (let day = 1; day < totalDays; day++) {
        const startDate = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth(), day);

        expiredEvents.push({
            id: `day-${day}`,
            type: EventTypes.EXPIRED,
            startDate: startDate,
            endDate: startDate,
        });
    }

    return expiredEvents;
};

export const createMinRentalDurationEventsPerDate = ({
    availabilityPerDate,
    bookingEvents,
    selectEvent,
}: {
    availabilityPerDate?: AvailabilityPerDate[];
    bookingEvents: CalendarEvent[];
    selectEvent: CalendarEvent;
}) => {
    const eventFollowingSelectionEvent = getBookingEventFollowingSelectionEvent({ bookingEvents, selectEvent });
    const updatedEvents: CalendarEvent[] = [];
    // Adjust the event following selection startDate to be a day later so the user can checkout on that day
    if (eventFollowingSelectionEvent) {
        const dayAfterStartDate = moveBookingEventStartDateForwardOneDay(eventFollowingSelectionEvent);

        eventFollowingSelectionEvent.startDate = dayAfterStartDate.startDate;
        eventFollowingSelectionEvent.endDate = dayAfterStartDate.endDate;
    }

    const doesSelectEndEventExist = selectEvent.endDate && selectEvent.startDate.getTime() !== selectEvent.endDate.getTime();

    const minDuration = availabilityPerDate?.find((rule) => {
        const ruleDate = new Date(rule.date);
        return (
            ruleDate.getFullYear() === selectEvent.startDate.getFullYear() &&
            ruleDate.getMonth() === selectEvent.startDate.getMonth() &&
            ruleDate.getDate() === selectEvent.startDate.getDate()
        );
    })?.minDuration;

    // If there is a minimum rental duration and the selectedEndDate does not exist
    if (!doesSelectEndEventExist && minDuration && minDuration > 1) {
        // Start a day after selected date since there is no need to put this event on selected day
        const minimumRentalDurationStartDate = new Date(selectEvent.startDate.getTime());
        minimumRentalDurationStartDate.setDate(selectEvent.startDate.getDate() + 1);
        // 1 day less since selected night is included
        let minimumRentalDurationEndDate = new Date(selectEvent.startDate.getTime());
        minimumRentalDurationEndDate.setDate(selectEvent.startDate.getDate() + minDuration - 1);

        // Don't allow minimumRentalDuration endDate to exceed the following event startDate
        if (eventFollowingSelectionEvent && minimumRentalDurationEndDate.getTime() >= eventFollowingSelectionEvent.startDate.getTime()) {
            const dayBeforeEventFollowingSelectionEventStartDate = new Date(eventFollowingSelectionEvent.startDate.getTime());
            dayBeforeEventFollowingSelectionEventStartDate.setDate(eventFollowingSelectionEvent.startDate.getDate() - 1);

            minimumRentalDurationEndDate = dayBeforeEventFollowingSelectionEventStartDate;
        }

        const minimumRentalDurationEvent: CalendarEvent = {
            id: 'minimumRentalDuration',
            // id: `minimumRentalDuration-${minDuration}`,
            type: EventTypes.MINIMUM_RENTAL_DURATION,
            startDate: minimumRentalDurationStartDate,
            endDate:
                eventFollowingSelectionEvent?.startDate &&
                minimumRentalDurationEndDate.getTime() <= eventFollowingSelectionEvent.startDate.getTime()
                    ? minimumRentalDurationEndDate
                    : eventFollowingSelectionEvent?.startDate || minimumRentalDurationEndDate,
        };

        updatedEvents.push(minimumRentalDurationEvent);
    }

    return updatedEvents;
};
